import { memo, useMemo } from 'react';
import { Link } from 'react-router-dom';
import _get from 'lodash/get';
import { getImageObject } from '../../helpers/featured-soundhumans';
import { TopCardProps } from '../../model/featured-soundhumans';
import { formatDate } from '../../helpers/date-time';
import ReadMoreButton from './ReadMoreButton';

const TopCard: React.FC<TopCardProps> = ({ soundHuman }) => {
  const detailPageLink = useMemo(() => (
    `/featured-soundhumans/${_get(soundHuman, 'fields.slug', '')}`
  ), [soundHuman]);

  return (
    <div className="border-b border-[#d0d0d0] mb-5">
      <div>
        {
          getImageObject(soundHuman, 'thumbnail')?.url && (
            <img
              src={getImageObject(soundHuman, 'thumbnail')?.url}
              alt={getImageObject(soundHuman, 'thumbnail')?.title}
              className="w-full object-cover md:hidden"
              loading='lazy'
            />
          )
        }
        {
          getImageObject(soundHuman, 'featuredImage')?.url && (
            <img
              src={getImageObject(soundHuman, 'featuredImage')?.url}
              alt={getImageObject(soundHuman, 'featuredImage')?.title}
              className="w-full max-h-[300px] object-cover hidden md:block"
              loading='lazy'
            />
          )
        }
        <div className="my-4">
          <h2 className="font-semibold text-20 text-[#222222]">
            <Link
              className="text-[#222222]"
              to={detailPageLink}
            >
              {_get(soundHuman, 'fields.fullName', '')}
            </Link>
          </h2>
          <span className="text-[#6C757D] text-16 leading-6">
            {formatDate(new Date(_get(soundHuman, 'fields.publishedDate', '')))}
          </span>
        </div>
        <p className="text-18 leading-6 text-[#666666] my-3">
          {_get(soundHuman, 'fields.shortDescription', '')}
        </p>
      </div>
      <div className="mb-5">
        <ReadMoreButton
          link={detailPageLink}
        />
      </div>
    </div>
  )
};

export default memo(TopCard);
