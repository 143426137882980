import { memo, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import _orderBy from 'lodash/orderBy';
import _get from 'lodash/get';
import { Entry } from 'contentful';
import { useAppContext } from '../context/app-context';
import { FeaturedSoundHuman } from '../model/featured-soundhumans';
import { filterItems } from '../helpers/contentful-helper';
import { getImageObject } from '../helpers/featured-soundhumans';
import LatestStoryCard from '../components/featured-soundhumans/LatestStoryCard';
import JotFormSubmit from '../components/featured-soundhumans/JotFormSubmit';
import Element from '../components/featured-soundhumans/elements/Element';

const SoundhumansDetailsPage: React.FC = () => {
  const params = useParams();
  const { slug = '' } = params ?? {};
  const { rawContentFul, allPosts } = useAppContext();

  const latestArticles = useMemo(() => (
    _orderBy(allPosts, 'published_at', 'desc').slice(0, 4)
  ), [allPosts]);
  const featuredSoundhumans: Entry<FeaturedSoundHuman>[] = useMemo(() => (
    _orderBy(filterItems(rawContentFul, 'featuredSoundhumans'), 'fields.publishedDate', 'desc')
  ) as Entry<FeaturedSoundHuman>[], [rawContentFul]);
  const soundHumansCollection = useMemo(() => {
    const obj: Record<string, any> = {};

    featuredSoundhumans.forEach((soundHuman) => {
      obj[_get(soundHuman, 'fields.slug')] = soundHuman;
    });

    return obj;
  }, [featuredSoundhumans]);
  const soundHuman: Entry<FeaturedSoundHuman> = useMemo(() => (
    soundHumansCollection?.[slug]
  ), [soundHumansCollection, slug]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, []);

  return (
    <div>
      {
        getImageObject(soundHuman, 'featuredImage')?.url && (
          <img
            src={getImageObject(soundHuman, 'featuredImage')?.url}
            alt={getImageObject(soundHuman, 'featuredImage')?.title}
            className="w-full object-cover"
          />
        )
      }
      <div className='px-4 lg:mx-16 py-7'>
        <div className='max-w-5xl w-full mx-auto'>
          {
            _get(soundHuman, 'fields.description.content', []).map((content: any, index: number) => (
              <Element key={index} content={content} />
            ))
          }
          <hr />
          <JotFormSubmit />
          <hr />
          <div className='my-10'>
            <h3 className='text-[#4A4A4A] font-semibold text-center
              leading-6 col-span-4 my-3 text-30'>
              THE LATEST NEWS
            </h3>
            <div className='text-22 text-[#666666] leading-6 text-center'>
              Check our latest news
            </div>
            <div className='grid grid-cols-1 lg:grid-cols-4 gap-6 my-6'>
              {
                latestArticles.map((article) => article && (
                  <LatestStoryCard key={article?.id} article={article} />
                ))
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(SoundhumansDetailsPage);
